import React from "react"
import PropTypes from "prop-types"

import "./ImageAlign.scss"

const ImageAlign = (props) => {
  let className = "image-align__center"
  if (props.position === "left") {
    className = "image-align__left"
  } else if (props.position === "right") {
    className = "image-align__right"
  } else if (props.position === "center") {
    className = "image-align__center"
  }

  return (
    <div className="image-align">
      <div className="image-align__container">
        <div className={className}>
          <div
            className={`${className}__container`}
            style={{ width: props.width }}
          >
            <img
              src={props.image.localFile.childImageSharp.fluid.src}
              alt={props.image.altText}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ImageAlign.propTypes = {
  image: PropTypes.object.isRequired,
  position: PropTypes.string,
  width: PropTypes.string,
}

export default ImageAlign
