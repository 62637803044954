import React from "react"
import PropTypes from "prop-types"

import { graphql, useStaticQuery } from "gatsby"

import Link from "../../utils/link"
import "./MenuBar.scss"

const MenuBar = ({ menuSlug }) => {
  const data = useStaticQuery(graphql`
    query {
      wpPost(slug: { eq: "menus" }) {
        menus {
          links {
            ... on WpPost_Menus_Links_Menus {
              menuName
              sidebar {
                slug
                link
              }
            }
          }
        }
      }
    }
  `)

  const menus = data.wpPost.menus.links

  return (
    <div>
      {menus.map((menu, index) => {
        if (menu.menuName === menuSlug)
          return (
            <nav key={index} className="menu-bar">
              <ul>
                {menu.sidebar.map((link) => {
                  return (
                    <li key={link.link}>
                      <Link
                        to={link.slug}
                        activeClassName="menu-bar__active"
                        className="menu-bar__link"
                      >
                        {link.link}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </nav>
          )
      })}
    </div>
  )
}

MenuBar.propTypes = {
  menuSlug: PropTypes.string.isRequired,
}

export default MenuBar
