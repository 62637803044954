import React from "react"
import PropTypes from "prop-types"

import ShapingMavenSidebar from "../shaping-maven-sidebar/ShapingMavenSidebar"

import "./ShapingFramework.scss"

function ShapingFramework({ rightSide, hideMenuBar, menuSlug }) {
  return (
    <div>
      <div className="shaping-maven-framework">
        <div className="shaping-maven-framework__container">
          {!hideMenuBar && (
            <div className="shaping-maven-framework__menu">
              <ShapingMavenSidebar menuSlug={menuSlug} />
            </div>
          )}
          <div
            className={`shaping-maven-framework__content ${
              hideMenuBar ? "shaping-maven-framework__content__full" : ""
            }`}
          >
            {rightSide.map((block, index) => {
              return (
                <div key={index}>
                  <p className="shaping-maven-framework__content__heading">
                    {block.heading}
                  </p>
                  <div className="shaping-maven-framework__content__para" dangerouslySetInnerHTML={{__html: block.description}} />
                  {block.isImagePresent && (
                    <div className="shaping-maven-framework__content__image">
                      <img
                        src={block.image.localFile.childImageSharp.fluid.src}
                        altText={block.image.altText}
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

ShapingFramework.propTypes = {
  rightSide: PropTypes.array,
  hideMenuBar: PropTypes.bool,
  menuSlug: PropTypes.string,
}

export default ShapingFramework
