import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Link from "../../utils/link"

import "./ShapingMavenSidebar.scss"

const ShapingMavenSidebar = ({ menuSlug }) => {
  const data = useStaticQuery(graphql`
    query {
      wpPost(slug: { eq: "menus" }) {
        menus {
          links {
            ... on WpPost_Menus_Links_Menus {
              menuName
              sidebar {
                slug
                link
              }
            }
          }
        }
      }
    }
  `)

  const menus = data.wpPost.menus.links

  return (
    <div>
      {menus.map((menu, index) => {
        if (menu.menuName === menuSlug)
          return (
            <div key={index} className="shaping-maven-sidebar">
              {menu.sidebar.map((link) => {
                return (
                  <p className="shaping-maven-sidebar__link" key={link.link}>
                    <Link
                      activeClassName="shaping-maven-sidebar__active"
                      to={link.slug}
                      title={link.link}
                    >
                      {link.link}
                    </Link>
                  </p>
                )
              })}
            </div>
          )
      })}
    </div>
  )
}

ShapingMavenSidebar.propTypes = {
  menuSlug: PropTypes.string,
}

export default ShapingMavenSidebar
