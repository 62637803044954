import React from "react"
import PropTypes from "prop-types"

import Mainbar from "../components/mainbar/Mainbar"

import "./ShapingHouseTemplate.scss"

const ShapingHouseTemplate = ({
  rightSideTitle,
  rightSideDescription,
  menuTitle,
  menuSlug,
}) => {
  return (
    <div>
      <div className="shaping-house">
        <div className="shaping-house__container">
          <Mainbar
            title={menuTitle}
            lead={rightSideTitle}
            subLead={null}
            para={rightSideDescription}
            menuSlug={menuSlug}
          />
        </div>
      </div>
    </div>
  )
}

ShapingHouseTemplate.propTypes = {
  rightSideTitle: PropTypes.string,
  rightSideDescription: PropTypes.string,
  menuSlug: PropTypes.string,
  menuTitle: PropTypes.string,
}

export default ShapingHouseTemplate
