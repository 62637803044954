import React from "react"
import PropTypes from "prop-types"

import ShapingMavenSidebar from "../shaping-maven-sidebar/ShapingMavenSidebar"

import "./ShapingFounders.scss"

function ShapingMavenFounders({ founders }) {
  return (
    <div>
      <div className="shaping-maven-founders">
        <div className="shaping-maven-founders__container">
          <ShapingMavenSidebar />
          <div className="shaping-maven-founders__content">
            <div className="shaping-maven-founders__founder__container">
              {founders.map((founder, index) => {
                return (
                  <div key={index}>
                    <div className="shaping-maven-founders__founder__image">
                      <img
                        src={founder.image.localFile.childImageSharp.fluid.src}
                        alt={founder.image.altText}
                        loading="lazy"
                      />
                    </div>
                    <h2 className="shaping-maven-founders__founder__name">
                      {founder.name}
                    </h2>
                    <p className="shaping-maven-founders__founder__role">
                      {founder.role}
                    </p>
                    <p className="shaping-maven-founders__founder__intro">
                      {founder.intro}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ShapingMavenFounders.propTypes = {
  founders: PropTypes.array,
}

export default ShapingMavenFounders
