/* eslint-disable react/display-name */
import React from "react"

import ImageDual from "../components/images-alignment/ImageDual"
import ImageAlign from "../components/images-alignment/ImageAlign"
import ShapingFounders from "../components/founders/ShapingFounders"
import ShapingFramework from "../components/framework/ShapingFramework"
import ShapingMavenHome from "../components/image/ShapingMavenHome.js"
import ShapingMaven from "../components/shaping-maven/ShapingMaven"
import ShapingHouseTemplate from "../templates/ShapingHouseTemplate"

const RenderBlock = (param, el, i, postType) => {
  if (param) param = param.replace(postType, "")

  const block = {
    _Acf_ContentBlocks_ShapingMavenTemplate: (element, index) => {
      return <ShapingMaven key={index} {...element} />
    },

    _Acf_ContentBlocks_DualImage: (element, index) => {
      return <ImageDual key={index} {...element} />
    },

    _Acf_ContentBlocks_SingleImage: (element, index) => {
      return <ImageAlign key={index} {...element} />
    },

    _Acf_ContentBlocks_FoundersTemplateNew: (element, index) => {
      return <ShapingFounders key={index} {...element} />
    },

    _Acf_ContentBlocks_FrameworkTemplateNew: (element, index) => {
      return <ShapingFramework key={index} {...element} />
    },

    _Acf_ContentBlocks_Image: (element, index) => {
      return <ShapingMavenHome key={index} {...element} />
    },

    _Acf_ContentBlocks_ShapingSpacesTop: (element, index) => {
      return <ShapingHouseTemplate key={index} {...element} />
    },
  }[param]

  if (!block) return null
  return block(el, i)
}

export default RenderBlock
