import React from "react"
import PropTypes from "prop-types"

const ShapingMavenHome = ({ image }) => {
  return (
    <img src={image.localFile.childImageSharp.fluid.src} alt={image.altText} />
  )
}

ShapingMavenHome.propTypes = {
  image: PropTypes.object,
}

export default ShapingMavenHome
