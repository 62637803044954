import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import SEO from "../components/seo"
import RenderBlock from "../utils/render-block"
import Header from "../components/header"

const PageTemplate = ({ data }) => {
  const { wpPage } = data

  return (
    <div>
      <SEO path={`/${wpPage.slug}/`} title={wpPage.title} />
      <Header />
      {wpPage.acf.contentBlocks.map((el, i) => {
        return RenderBlock(el.fieldGroupName, el, i, "page")
      })}
    </div>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      slug
      title
      acf {
        fieldGroupName
        contentBlocks {
          ... on WpPage_Acf_ContentBlocks_ShapingMavenTemplate {
            fieldGroupName
            menuSlug
            rightSideImage {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
              altText
            }
          }
          ... on WpPage_Acf_ContentBlocks_DualImage {
            fieldGroupName
            width
            rightImage {
              altText
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            leftImage {
              altText
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_SingleImage {
            fieldGroupName
            position
            width
            image {
              altText
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_FoundersTemplateNew {
            fieldGroupName
            founders {
              role
              name
              intro
              fieldGroupName
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_ShapingSpacesTop {
            fieldGroupName
            rightSideDescription
            rightSideTitle
            menuTitle
            menuSlug
          }
          ... on WpPage_Acf_ContentBlocks_FrameworkTemplateNew {
            fieldGroupName
            menuSlug
            hideMenuBar
            rightSide {
              isImagePresent
              heading
              fieldGroupName
              description
              caption
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_FoundationsTemplate {
            fieldGroupName
            rightSideText {
              text
              fieldGroupName
            }
          }
        }
      }
    }
  }
`
