import React from "react"
import PropTypes from "prop-types"

import MenuBar from "../menu-bar/MenuBar"

import "./Mainbar.scss"

const Mainbar = (props) => {
  return (
    <div className="mainbar">
      <h1 className="mainbar__title">{props.title}</h1>
      <div className="mainbar__grid-container">
        <div className="mainbar__links">
          <MenuBar menuSlug={props.menuSlug} />
        </div>
        <div className="mainbar__desc">
          <p
            className={`${
              props.subLead ? "mainbar__lead-sub" : "mainbar__lead"
            }`}
          >
            {props.lead}
          </p>
          <p className="mainbar__lead mainbar__sub-lead">{props.subLead}</p>
          <p className="mainbar__para">{props.para}</p>
        </div>
      </div>
    </div>
  )
}

Mainbar.propTypes = {
  lead: PropTypes.string,
  para: PropTypes.string,
  img: PropTypes.any,
  slug: PropTypes.string,
  title: PropTypes.string,
  subLead: PropTypes.string,
  menuSlug: PropTypes.string,
}

export default Mainbar
