import React from "react"
import PropTypes from "prop-types"

import "./ImageDual.scss"

const ImageDual = (props) => {
  return (
    <div className="image-dual">
      <div className="image-dual__container">
        <div className="image-dual__left" style={{ width: props.width }}>
          <img
            src={props.leftImage.localFile.childImageSharp.fluid.src}
            alt={props.leftImage.altText}
            loading="lazy"
          />
        </div>
        <div className="image-dual__right" style={{ width: props.width }}>
          <img
            src={props.rightImage.localFile.childImageSharp.fluid.src}
            alt={props.rightImage.altText}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  )
}

ImageDual.propTypes = {
  leftImage: PropTypes.any,
  rightImage: PropTypes.any,
  width: PropTypes.string,
}

export default ImageDual
