import React from "react"
import PropTypes from "prop-types"

import ShapingMavenSidebar from "../shaping-maven-sidebar/ShapingMavenSidebar"

import ShapingMavenHome from "../image/ShapingMavenHome"

import "./ShapingMaven.scss"

const ShapingMaven = ({ rightSideImage, menuSlug }) => {
  return (
    <div>
      <div className="shaping-maven">
        <div className="shaping-maven__container">
          <div className="shaping-maven__menu">
            <ShapingMavenSidebar menuSlug={menuSlug} />
          </div>
          <div className="shaping-maven__image">
            <ShapingMavenHome image={rightSideImage} />
          </div>
        </div>
      </div>
    </div>
  )
}

ShapingMaven.propTypes = {
  menuSlug: PropTypes.string,
  rightSideImage: PropTypes.object,
}

export default ShapingMaven
